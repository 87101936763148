var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('v-data-table',{attrs:{"headers":_setup.headers,"items":_setup.storeFileBlobs,"server-items-length":_setup.total,"options":_setup.options,"footer-props":{
      showCurrentPage: true,
      showFirstLastPage: true,
      itemsPerPageOptions: [
        10,
        25,
        100,
      ],
    }},on:{"update:options":_setup.updateOptions},scopedSlots:_vm._u([{key:"item.blobIdentifier",fn:function({ item }){return [_vm._v(" "+_vm._s(item.blobIdentifier)+" "),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _setup.copyTextToClipboard(item.blobIdentifier)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-content-copy ")])],1)]}},{key:"item.uploadTimestamp",fn:function({ item }){return [_vm._v(" "+_vm._s(_setup.timestampToDisplayString(item.uploadTimestamp))+" ")]}},{key:"item.type",fn:function({ item }){return [_vm._v(" "+_vm._s(item.type)+" ")]}},{key:"item.size",fn:function({ item }){return [_vm._v(" "+_vm._s(item.size)+" ")]}},{key:"item.contentHash",fn:function({ item }){return [_vm._v(" "+_vm._s(_setup.abbreviateHash(item.contentHash))+" "),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _setup.copyTextToClipboard(item.contentHash)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-content-copy ")])],1)]}},{key:"item.status",fn:function({ item }){return [_vm._v(" "+_vm._s(item.status)+" ")]}},{key:"item.operations",fn:function({ item }){return [_c('v-btn',{on:{"click":function($event){return _setup.download(item.blobIdentifier)}}},[_vm._v(" Download ")])]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }