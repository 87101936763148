export const version = process.env.VUE_APP_VERSION;

export const firebaseConfig = JSON.parse(process.env.VUE_APP_FIREBASE_CONFIG);

export const backendAPIEndpoint = process.env.VUE_APP_BACKEND_API_ENDPOINT;

export const httpSymbolStoreEndpoint =
  process.env.VUE_APP_BACKEND_API_ENDPOINT + "/httpSymbolStore";

export const authDomain = process.env.VUE_APP_AUTH_DOMAIN;
