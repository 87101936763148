var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('v-data-table',{attrs:{"headers":_setup.headers,"items":_setup.storeFiles,"server-items-length":_setup.total,"options":_setup.options,"footer-props":{
      showCurrentPage: true,
      showFirstLastPage: true,
      itemsPerPageOptions: [
        10,
        25,
        100,
      ],
    }},on:{"update:options":_setup.updateOptions},scopedSlots:_vm._u([{key:"item.fileName",fn:function({ item }){return [_c('router-link',{attrs:{"to":{ name: 'storeFileBlobs', params: { store: _vm.store, file: item.fileName } }}},[_vm._v(_vm._s(item.fileName))])]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }