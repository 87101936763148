/* tslint:disable */
/* eslint-disable */
/**
 * Cloud Symbol Server Admin API
 * This is the API that is used to manage stores and uploads in Cloud Symbol Server
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface CreateStoreUploadFileRequest
 */
export interface CreateStoreUploadFileRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateStoreUploadFileRequest
     */
    'fileName': string;
    /**
     * 
     * @type {string}
     * @memberof CreateStoreUploadFileRequest
     */
    'blobIdentifier'?: string;
    /**
     * 
     * @type {StoreFileBlobType}
     * @memberof CreateStoreUploadFileRequest
     */
    'type'?: StoreFileBlobType;
    /**
     * 
     * @type {number}
     * @memberof CreateStoreUploadFileRequest
     */
    'size'?: number;
    /**
     * 
     * @type {string}
     * @memberof CreateStoreUploadFileRequest
     */
    'contentHash'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateStoreUploadFileRequest
     */
    'hash'?: string;
}
/**
 * 
 * @export
 * @interface CreateStoreUploadRequest
 */
export interface CreateStoreUploadRequest {
    /**
     * When present and set to true, the client will provide progress updates; Legacy clients will create an upload, then upload the required files to GCS, without progress/completion callbacks
     * @type {boolean}
     * @memberof CreateStoreUploadRequest
     */
    'useProgressApi'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateStoreUploadRequest
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateStoreUploadRequest
     */
    'buildId'?: string;
    /**
     * 
     * @type {Array<CreateStoreUploadFileRequest>}
     * @memberof CreateStoreUploadRequest
     */
    'files'?: Array<CreateStoreUploadFileRequest>;
}
/**
 * 
 * @export
 * @interface CreateStoreUploadResponse
 */
export interface CreateStoreUploadResponse {
    /**
     * 
     * @type {number}
     * @memberof CreateStoreUploadResponse
     */
    'uploadId': number;
    /**
     * 
     * @type {string}
     * @memberof CreateStoreUploadResponse
     */
    'id': string;
    /**
     * 
     * @type {Array<UploadFileResponse>}
     * @memberof CreateStoreUploadResponse
     */
    'files': Array<UploadFileResponse>;
}
/**
 * 
 * @export
 * @interface CreateTokenResponse
 */
export interface CreateTokenResponse {
    /**
     * Personal Access Token
     * @type {string}
     * @memberof CreateTokenResponse
     */
    'token': string;
}
/**
 * 
 * @export
 * @interface GetStoreFileBlobDownloadUrlResponse
 */
export interface GetStoreFileBlobDownloadUrlResponse {
    /**
     * 
     * @type {string}
     * @memberof GetStoreFileBlobDownloadUrlResponse
     */
    'method': string;
    /**
     * 
     * @type {string}
     * @memberof GetStoreFileBlobDownloadUrlResponse
     */
    'url': string;
}
/**
 * 
 * @export
 * @interface GetStoreFileBlobResponse
 */
export interface GetStoreFileBlobResponse {
    /**
     * 
     * @type {string}
     * @memberof GetStoreFileBlobResponse
     */
    'blobIdentifier': string;
    /**
     * Upload timestamp, in RFC3339 format
     * @type {string}
     * @memberof GetStoreFileBlobResponse
     */
    'uploadTimestamp': string;
    /**
     * 
     * @type {StoreFileBlobType}
     * @memberof GetStoreFileBlobResponse
     */
    'type'?: StoreFileBlobType;
    /**
     * 
     * @type {number}
     * @memberof GetStoreFileBlobResponse
     */
    'size'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetStoreFileBlobResponse
     */
    'contentHash'?: string;
    /**
     * 
     * @type {StoreFileBlobStatus}
     * @memberof GetStoreFileBlobResponse
     */
    'status': StoreFileBlobStatus;
}
/**
 * 
 * @export
 * @interface GetStoreFileBlobsResponse
 */
export interface GetStoreFileBlobsResponse {
    /**
     * 
     * @type {Array<GetStoreFileBlobResponse>}
     * @memberof GetStoreFileBlobsResponse
     */
    'blobs': Array<GetStoreFileBlobResponse>;
    /**
     * 
     * @type {PaginationResponse}
     * @memberof GetStoreFileBlobsResponse
     */
    'pagination': PaginationResponse;
}
/**
 * 
 * @export
 * @interface GetStoreFilesResponse
 */
export interface GetStoreFilesResponse {
    /**
     * 
     * @type {Array<string>}
     * @memberof GetStoreFilesResponse
     */
    'files': Array<string>;
    /**
     * 
     * @type {PaginationResponse}
     * @memberof GetStoreFilesResponse
     */
    'pagination': PaginationResponse;
}
/**
 * 
 * @export
 * @interface GetStoreUploadFileResponse
 */
export interface GetStoreUploadFileResponse {
    /**
     * 
     * @type {string}
     * @memberof GetStoreUploadFileResponse
     */
    'fileName': string;
    /**
     * 
     * @type {string}
     * @memberof GetStoreUploadFileResponse
     */
    'blobIdentifier': string;
    /**
     * 
     * @type {StoreUploadFileStatus}
     * @memberof GetStoreUploadFileResponse
     */
    'status': StoreUploadFileStatus;
}
/**
 * 
 * @export
 * @interface GetStoreUploadResponse
 */
export interface GetStoreUploadResponse {
    /**
     * 
     * @type {number}
     * @memberof GetStoreUploadResponse
     */
    'uploadId': number;
    /**
     * 
     * @type {string}
     * @memberof GetStoreUploadResponse
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof GetStoreUploadResponse
     */
    'buildId': string;
    /**
     * 
     * @type {string}
     * @memberof GetStoreUploadResponse
     */
    'uploadTimestamp': string;
    /**
     * 
     * @type {string}
     * @memberof GetStoreUploadResponse
     */
    'expiryTimestamp': string;
    /**
     * 
     * @type {Array<GetStoreUploadFileResponse>}
     * @memberof GetStoreUploadResponse
     */
    'files': Array<GetStoreUploadFileResponse>;
    /**
     * 
     * @type {StoreUploadStatus}
     * @memberof GetStoreUploadResponse
     */
    'status': StoreUploadStatus;
}
/**
 * 
 * @export
 * @interface GetStoreUploadsResponse
 */
export interface GetStoreUploadsResponse {
    /**
     * 
     * @type {Array<GetStoreUploadResponse>}
     * @memberof GetStoreUploadsResponse
     */
    'uploads': Array<GetStoreUploadResponse>;
    /**
     * 
     * @type {PaginationResponse}
     * @memberof GetStoreUploadsResponse
     */
    'pagination': PaginationResponse;
}
/**
 * 
 * @export
 * @interface GetTokenResponse
 */
export interface GetTokenResponse {
    /**
     * Personal Access Token This token can be used for authentication when accessing non-token related APIs
     * @type {string}
     * @memberof GetTokenResponse
     */
    'token': string;
    /**
     * Textual description of token Users fill this in to remind themselves the purpose of a token and/or where it is used
     * @type {string}
     * @memberof GetTokenResponse
     */
    'description': string;
    /**
     * Creation timestamp, in RFC3339 format
     * @type {string}
     * @memberof GetTokenResponse
     */
    'creationTimestamp': string;
}
/**
 * 
 * @export
 * @interface MessageResponse
 */
export interface MessageResponse {
    /**
     * 
     * @type {string}
     * @memberof MessageResponse
     */
    'message': string;
}
/**
 * 
 * @export
 * @interface PaginationResponse
 */
export interface PaginationResponse {
    /**
     * 
     * @type {number}
     * @memberof PaginationResponse
     */
    'total': number;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const StoreFileBlobStatus = {
    Pending: 'pending',
    Present: 'present'
} as const;

export type StoreFileBlobStatus = typeof StoreFileBlobStatus[keyof typeof StoreFileBlobStatus];


/**
 * 
 * @export
 * @enum {string}
 */

export const StoreFileBlobType = {
    Unknown: 'unknown',
    Pe: 'pe',
    Pdb: 'pdb'
} as const;

export type StoreFileBlobType = typeof StoreFileBlobType[keyof typeof StoreFileBlobType];


/**
 * 
 * @export
 * @enum {string}
 */

export const StoreUploadFileStatus = {
    Unknown: 'unknown',
    AlreadyPresent: 'already_present',
    Pending: 'pending',
    Completed: 'completed',
    Aborted: 'aborted',
    Expired: 'expired'
} as const;

export type StoreUploadFileStatus = typeof StoreUploadFileStatus[keyof typeof StoreUploadFileStatus];


/**
 * 
 * @export
 * @enum {string}
 */

export const StoreUploadStatus = {
    Unknown: 'unknown',
    InProgress: 'in_progress',
    Completed: 'completed',
    Aborted: 'aborted',
    Expired: 'expired'
} as const;

export type StoreUploadStatus = typeof StoreUploadStatus[keyof typeof StoreUploadStatus];


/**
 * 
 * @export
 * @interface UpdateTokenRequest
 */
export interface UpdateTokenRequest {
    /**
     * Textual description of token Users fill this in to remind themselves the purpose of a token and/or where it is used
     * @type {string}
     * @memberof UpdateTokenRequest
     */
    'description': string;
}
/**
 * 
 * @export
 * @interface UploadFileResponse
 */
export interface UploadFileResponse {
    /**
     * 
     * @type {string}
     * @memberof UploadFileResponse
     */
    'fileName': string;
    /**
     * 
     * @type {string}
     * @memberof UploadFileResponse
     */
    'blobIdentifier': string;
    /**
     * Short-lived signed URL where the client should upload the file to, or blank if the file already exists in the storage backend
     * @type {string}
     * @memberof UploadFileResponse
     */
    'url'?: string;
    /**
     * 
     * @type {string}
     * @memberof UploadFileResponse
     */
    'hash'?: string;
}

/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create a new store
         * @param {string} storeId ID of store to create
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createStore: async (storeId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('createStore', 'storeId', storeId)
            const localVarPath = `/stores/{storeId}`
                .replace(`{${"storeId"}}`, encodeURIComponent(String(storeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication emailAndPat required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Start a new upload
         * @param {string} storeId ID of the store containing the upload
         * @param {CreateStoreUploadRequest} createStoreUploadRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createStoreUpload: async (storeId: string, createStoreUploadRequest: CreateStoreUploadRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('createStoreUpload', 'storeId', storeId)
            // verify required parameter 'createStoreUploadRequest' is not null or undefined
            assertParamExists('createStoreUpload', 'createStoreUploadRequest', createStoreUploadRequest)
            const localVarPath = `/stores/{storeId}/uploads`
                .replace(`{${"storeId"}}`, encodeURIComponent(String(storeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication emailAndPat required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createStoreUploadRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a new token for current user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createToken: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/tokens`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication emailAndPat required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete an existing store
         * @param {string} storeId ID of store to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteStore: async (storeId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('deleteStore', 'storeId', storeId)
            const localVarPath = `/stores/{storeId}`
                .replace(`{${"storeId"}}`, encodeURIComponent(String(storeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication emailAndPat required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete a token for current user
         * @param {string} token ID of the token to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteToken: async (token: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'token' is not null or undefined
            assertParamExists('deleteToken', 'token', token)
            const localVarPath = `/tokens/{token}`
                .replace(`{${"token"}}`, encodeURIComponent(String(token)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication emailAndPat required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Expire store upload and consider files for GC
         * @param {number} uploadId ID of the upload to fetch
         * @param {string} storeId ID of the store containing the upload
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        expireStoreUpload: async (uploadId: number, storeId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uploadId' is not null or undefined
            assertParamExists('expireStoreUpload', 'uploadId', uploadId)
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('expireStoreUpload', 'storeId', storeId)
            const localVarPath = `/stores/{storeId}/uploads/{uploadId}/expire`
                .replace(`{${"uploadId"}}`, encodeURIComponent(String(uploadId)))
                .replace(`{${"storeId"}}`, encodeURIComponent(String(storeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication emailAndPat required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Request download URL for the binary blob associated with a particular store/file/blob-id
         * @param {string} storeId ID of the store containing the file
         * @param {string} fileId ID of the file
         * @param {string} blobId ID of the blob
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStoreFileBlobDownloadUrl: async (storeId: string, fileId: string, blobId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('getStoreFileBlobDownloadUrl', 'storeId', storeId)
            // verify required parameter 'fileId' is not null or undefined
            assertParamExists('getStoreFileBlobDownloadUrl', 'fileId', fileId)
            // verify required parameter 'blobId' is not null or undefined
            assertParamExists('getStoreFileBlobDownloadUrl', 'blobId', blobId)
            const localVarPath = `/stores/{storeId}/files/{fileId}/blobs/{blobId}/getDownloadUrl`
                .replace(`{${"storeId"}}`, encodeURIComponent(String(storeId)))
                .replace(`{${"fileId"}}`, encodeURIComponent(String(fileId)))
                .replace(`{${"blobId"}}`, encodeURIComponent(String(blobId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication emailAndPat required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Fetch a list of blobs for a specific file in store
         * @param {string} storeId ID of the store containing the file
         * @param {string} fileId ID of the file
         * @param {string} [sort] Sort the content of the response according to a given key, potentially in descending order
         * @param {number} [offset] How many entries to skip (used for pagination of results)
         * @param {number} [limit] Max number of results to return (used for pagination of results)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStoreFileBlobs: async (storeId: string, fileId: string, sort?: string, offset?: number, limit?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('getStoreFileBlobs', 'storeId', storeId)
            // verify required parameter 'fileId' is not null or undefined
            assertParamExists('getStoreFileBlobs', 'fileId', fileId)
            const localVarPath = `/stores/{storeId}/files/{fileId}/blobs`
                .replace(`{${"storeId"}}`, encodeURIComponent(String(storeId)))
                .replace(`{${"fileId"}}`, encodeURIComponent(String(fileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication emailAndPat required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Fetch a list of files in store
         * @param {string} storeId ID of the store containing the files
         * @param {string} [sort] Sort the content of the response according to a given key, potentially in descending order
         * @param {number} [offset] How many entries to skip (used for pagination of results)
         * @param {number} [limit] Max number of results to return (used for pagination of results)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStoreFiles: async (storeId: string, sort?: string, offset?: number, limit?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('getStoreFiles', 'storeId', storeId)
            const localVarPath = `/stores/{storeId}/files`
                .replace(`{${"storeId"}}`, encodeURIComponent(String(storeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication emailAndPat required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Fetch an upload
         * @param {number} uploadId ID of the upload to fetch
         * @param {string} storeId ID of the store containing the upload
         * @param {string} [sort] Sort the content of the response according to a given key, potentially in descending order
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStoreUpload: async (uploadId: number, storeId: string, sort?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uploadId' is not null or undefined
            assertParamExists('getStoreUpload', 'uploadId', uploadId)
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('getStoreUpload', 'storeId', storeId)
            const localVarPath = `/stores/{storeId}/uploads/{uploadId}`
                .replace(`{${"uploadId"}}`, encodeURIComponent(String(uploadId)))
                .replace(`{${"storeId"}}`, encodeURIComponent(String(storeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication emailAndPat required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Fetch a list of uploads in store
         * @param {string} storeId ID of the store containing the uploads
         * @param {string} [sort] Sort the content of the response according to a given key, potentially in descending order
         * @param {number} [offset] How many entries to skip (used for pagination of results)
         * @param {number} [limit] Max number of results to return (used for pagination of results)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStoreUploads: async (storeId: string, sort?: string, offset?: number, limit?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('getStoreUploads', 'storeId', storeId)
            const localVarPath = `/stores/{storeId}/uploads`
                .replace(`{${"storeId"}}`, encodeURIComponent(String(storeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication emailAndPat required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Fetch a list of all stores
         * @param {string} [sort] Sort the content of the response according to a given key, potentially in descending order
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStores: async (sort?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/stores`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication emailAndPat required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Fetch a token for current user
         * @param {string} token ID of the token to fetch
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getToken: async (token: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'token' is not null or undefined
            assertParamExists('getToken', 'token', token)
            const localVarPath = `/tokens/{token}`
                .replace(`{${"token"}}`, encodeURIComponent(String(token)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication emailAndPat required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Fetch a list of all tokens for current user
         * @param {string} [sort] Sort the content of the response according to a given key, potentially in descending order
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTokens: async (sort?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/tokens`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication emailAndPat required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Mark an upload as aborted
         * @param {number} uploadId ID of the upload to mark as aborted
         * @param {string} storeId ID of the store containing the upload
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        markStoreUploadAborted: async (uploadId: number, storeId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uploadId' is not null or undefined
            assertParamExists('markStoreUploadAborted', 'uploadId', uploadId)
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('markStoreUploadAborted', 'storeId', storeId)
            const localVarPath = `/stores/{storeId}/uploads/{uploadId}/aborted`
                .replace(`{${"uploadId"}}`, encodeURIComponent(String(uploadId)))
                .replace(`{${"storeId"}}`, encodeURIComponent(String(storeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication emailAndPat required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Mark an upload as completed
         * @param {number} uploadId ID of the upload to fetch
         * @param {string} storeId ID of the store containing the upload
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        markStoreUploadCompleted: async (uploadId: number, storeId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uploadId' is not null or undefined
            assertParamExists('markStoreUploadCompleted', 'uploadId', uploadId)
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('markStoreUploadCompleted', 'storeId', storeId)
            const localVarPath = `/stores/{storeId}/uploads/{uploadId}/completed`
                .replace(`{${"uploadId"}}`, encodeURIComponent(String(uploadId)))
                .replace(`{${"storeId"}}`, encodeURIComponent(String(storeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication emailAndPat required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Mark a file within an upload as uploaded
         * @param {number} uploadId ID of the upload to fetch
         * @param {string} storeId ID of the store containing the upload
         * @param {number} fileId Index of the file within the upload that should be marked as uploaded
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        markStoreUploadFileUploaded: async (uploadId: number, storeId: string, fileId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uploadId' is not null or undefined
            assertParamExists('markStoreUploadFileUploaded', 'uploadId', uploadId)
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('markStoreUploadFileUploaded', 'storeId', storeId)
            // verify required parameter 'fileId' is not null or undefined
            assertParamExists('markStoreUploadFileUploaded', 'fileId', fileId)
            const localVarPath = `/stores/{storeId}/uploads/{uploadId}/files/{fileId}/uploaded`
                .replace(`{${"uploadId"}}`, encodeURIComponent(String(uploadId)))
                .replace(`{${"storeId"}}`, encodeURIComponent(String(storeId)))
                .replace(`{${"fileId"}}`, encodeURIComponent(String(fileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication emailAndPat required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update details of a token for current user
         * @param {string} token ID of the token to update
         * @param {UpdateTokenRequest} updateTokenRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateToken: async (token: string, updateTokenRequest: UpdateTokenRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'token' is not null or undefined
            assertParamExists('updateToken', 'token', token)
            // verify required parameter 'updateTokenRequest' is not null or undefined
            assertParamExists('updateToken', 'updateTokenRequest', updateTokenRequest)
            const localVarPath = `/tokens/{token}`
                .replace(`{${"token"}}`, encodeURIComponent(String(token)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication emailAndPat required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateTokenRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DefaultApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create a new store
         * @param {string} storeId ID of store to create
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createStore(storeId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createStore(storeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Start a new upload
         * @param {string} storeId ID of the store containing the upload
         * @param {CreateStoreUploadRequest} createStoreUploadRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createStoreUpload(storeId: string, createStoreUploadRequest: CreateStoreUploadRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateStoreUploadResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createStoreUpload(storeId, createStoreUploadRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create a new token for current user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createToken(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateTokenResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createToken(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete an existing store
         * @param {string} storeId ID of store to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteStore(storeId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteStore(storeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete a token for current user
         * @param {string} token ID of the token to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteToken(token: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteToken(token, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Expire store upload and consider files for GC
         * @param {number} uploadId ID of the upload to fetch
         * @param {string} storeId ID of the store containing the upload
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async expireStoreUpload(uploadId: number, storeId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.expireStoreUpload(uploadId, storeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Request download URL for the binary blob associated with a particular store/file/blob-id
         * @param {string} storeId ID of the store containing the file
         * @param {string} fileId ID of the file
         * @param {string} blobId ID of the blob
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStoreFileBlobDownloadUrl(storeId: string, fileId: string, blobId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetStoreFileBlobDownloadUrlResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getStoreFileBlobDownloadUrl(storeId, fileId, blobId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Fetch a list of blobs for a specific file in store
         * @param {string} storeId ID of the store containing the file
         * @param {string} fileId ID of the file
         * @param {string} [sort] Sort the content of the response according to a given key, potentially in descending order
         * @param {number} [offset] How many entries to skip (used for pagination of results)
         * @param {number} [limit] Max number of results to return (used for pagination of results)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStoreFileBlobs(storeId: string, fileId: string, sort?: string, offset?: number, limit?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetStoreFileBlobsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getStoreFileBlobs(storeId, fileId, sort, offset, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Fetch a list of files in store
         * @param {string} storeId ID of the store containing the files
         * @param {string} [sort] Sort the content of the response according to a given key, potentially in descending order
         * @param {number} [offset] How many entries to skip (used for pagination of results)
         * @param {number} [limit] Max number of results to return (used for pagination of results)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStoreFiles(storeId: string, sort?: string, offset?: number, limit?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetStoreFilesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getStoreFiles(storeId, sort, offset, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Fetch an upload
         * @param {number} uploadId ID of the upload to fetch
         * @param {string} storeId ID of the store containing the upload
         * @param {string} [sort] Sort the content of the response according to a given key, potentially in descending order
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStoreUpload(uploadId: number, storeId: string, sort?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetStoreUploadResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getStoreUpload(uploadId, storeId, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Fetch a list of uploads in store
         * @param {string} storeId ID of the store containing the uploads
         * @param {string} [sort] Sort the content of the response according to a given key, potentially in descending order
         * @param {number} [offset] How many entries to skip (used for pagination of results)
         * @param {number} [limit] Max number of results to return (used for pagination of results)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStoreUploads(storeId: string, sort?: string, offset?: number, limit?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetStoreUploadsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getStoreUploads(storeId, sort, offset, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Fetch a list of all stores
         * @param {string} [sort] Sort the content of the response according to a given key, potentially in descending order
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStores(sort?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getStores(sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Fetch a token for current user
         * @param {string} token ID of the token to fetch
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getToken(token: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetTokenResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getToken(token, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Fetch a list of all tokens for current user
         * @param {string} [sort] Sort the content of the response according to a given key, potentially in descending order
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTokens(sort?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GetTokenResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTokens(sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Mark an upload as aborted
         * @param {number} uploadId ID of the upload to mark as aborted
         * @param {string} storeId ID of the store containing the upload
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async markStoreUploadAborted(uploadId: number, storeId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.markStoreUploadAborted(uploadId, storeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Mark an upload as completed
         * @param {number} uploadId ID of the upload to fetch
         * @param {string} storeId ID of the store containing the upload
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async markStoreUploadCompleted(uploadId: number, storeId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.markStoreUploadCompleted(uploadId, storeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Mark a file within an upload as uploaded
         * @param {number} uploadId ID of the upload to fetch
         * @param {string} storeId ID of the store containing the upload
         * @param {number} fileId Index of the file within the upload that should be marked as uploaded
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async markStoreUploadFileUploaded(uploadId: number, storeId: string, fileId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.markStoreUploadFileUploaded(uploadId, storeId, fileId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update details of a token for current user
         * @param {string} token ID of the token to update
         * @param {UpdateTokenRequest} updateTokenRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateToken(token: string, updateTokenRequest: UpdateTokenRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateToken(token, updateTokenRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DefaultApiFp(configuration)
    return {
        /**
         * 
         * @summary Create a new store
         * @param {string} storeId ID of store to create
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createStore(storeId: string, options?: any): AxiosPromise<void> {
            return localVarFp.createStore(storeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Start a new upload
         * @param {string} storeId ID of the store containing the upload
         * @param {CreateStoreUploadRequest} createStoreUploadRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createStoreUpload(storeId: string, createStoreUploadRequest: CreateStoreUploadRequest, options?: any): AxiosPromise<CreateStoreUploadResponse> {
            return localVarFp.createStoreUpload(storeId, createStoreUploadRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create a new token for current user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createToken(options?: any): AxiosPromise<CreateTokenResponse> {
            return localVarFp.createToken(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete an existing store
         * @param {string} storeId ID of store to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteStore(storeId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteStore(storeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete a token for current user
         * @param {string} token ID of the token to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteToken(token: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteToken(token, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Expire store upload and consider files for GC
         * @param {number} uploadId ID of the upload to fetch
         * @param {string} storeId ID of the store containing the upload
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        expireStoreUpload(uploadId: number, storeId: string, options?: any): AxiosPromise<void> {
            return localVarFp.expireStoreUpload(uploadId, storeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Request download URL for the binary blob associated with a particular store/file/blob-id
         * @param {string} storeId ID of the store containing the file
         * @param {string} fileId ID of the file
         * @param {string} blobId ID of the blob
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStoreFileBlobDownloadUrl(storeId: string, fileId: string, blobId: string, options?: any): AxiosPromise<GetStoreFileBlobDownloadUrlResponse> {
            return localVarFp.getStoreFileBlobDownloadUrl(storeId, fileId, blobId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Fetch a list of blobs for a specific file in store
         * @param {string} storeId ID of the store containing the file
         * @param {string} fileId ID of the file
         * @param {string} [sort] Sort the content of the response according to a given key, potentially in descending order
         * @param {number} [offset] How many entries to skip (used for pagination of results)
         * @param {number} [limit] Max number of results to return (used for pagination of results)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStoreFileBlobs(storeId: string, fileId: string, sort?: string, offset?: number, limit?: number, options?: any): AxiosPromise<GetStoreFileBlobsResponse> {
            return localVarFp.getStoreFileBlobs(storeId, fileId, sort, offset, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Fetch a list of files in store
         * @param {string} storeId ID of the store containing the files
         * @param {string} [sort] Sort the content of the response according to a given key, potentially in descending order
         * @param {number} [offset] How many entries to skip (used for pagination of results)
         * @param {number} [limit] Max number of results to return (used for pagination of results)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStoreFiles(storeId: string, sort?: string, offset?: number, limit?: number, options?: any): AxiosPromise<GetStoreFilesResponse> {
            return localVarFp.getStoreFiles(storeId, sort, offset, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Fetch an upload
         * @param {number} uploadId ID of the upload to fetch
         * @param {string} storeId ID of the store containing the upload
         * @param {string} [sort] Sort the content of the response according to a given key, potentially in descending order
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStoreUpload(uploadId: number, storeId: string, sort?: string, options?: any): AxiosPromise<GetStoreUploadResponse> {
            return localVarFp.getStoreUpload(uploadId, storeId, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Fetch a list of uploads in store
         * @param {string} storeId ID of the store containing the uploads
         * @param {string} [sort] Sort the content of the response according to a given key, potentially in descending order
         * @param {number} [offset] How many entries to skip (used for pagination of results)
         * @param {number} [limit] Max number of results to return (used for pagination of results)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStoreUploads(storeId: string, sort?: string, offset?: number, limit?: number, options?: any): AxiosPromise<GetStoreUploadsResponse> {
            return localVarFp.getStoreUploads(storeId, sort, offset, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Fetch a list of all stores
         * @param {string} [sort] Sort the content of the response according to a given key, potentially in descending order
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStores(sort?: string, options?: any): AxiosPromise<Array<string>> {
            return localVarFp.getStores(sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Fetch a token for current user
         * @param {string} token ID of the token to fetch
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getToken(token: string, options?: any): AxiosPromise<GetTokenResponse> {
            return localVarFp.getToken(token, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Fetch a list of all tokens for current user
         * @param {string} [sort] Sort the content of the response according to a given key, potentially in descending order
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTokens(sort?: string, options?: any): AxiosPromise<Array<GetTokenResponse>> {
            return localVarFp.getTokens(sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Mark an upload as aborted
         * @param {number} uploadId ID of the upload to mark as aborted
         * @param {string} storeId ID of the store containing the upload
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        markStoreUploadAborted(uploadId: number, storeId: string, options?: any): AxiosPromise<void> {
            return localVarFp.markStoreUploadAborted(uploadId, storeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Mark an upload as completed
         * @param {number} uploadId ID of the upload to fetch
         * @param {string} storeId ID of the store containing the upload
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        markStoreUploadCompleted(uploadId: number, storeId: string, options?: any): AxiosPromise<void> {
            return localVarFp.markStoreUploadCompleted(uploadId, storeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Mark a file within an upload as uploaded
         * @param {number} uploadId ID of the upload to fetch
         * @param {string} storeId ID of the store containing the upload
         * @param {number} fileId Index of the file within the upload that should be marked as uploaded
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        markStoreUploadFileUploaded(uploadId: number, storeId: string, fileId: number, options?: any): AxiosPromise<void> {
            return localVarFp.markStoreUploadFileUploaded(uploadId, storeId, fileId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update details of a token for current user
         * @param {string} token ID of the token to update
         * @param {UpdateTokenRequest} updateTokenRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateToken(token: string, updateTokenRequest: UpdateTokenRequest, options?: any): AxiosPromise<void> {
            return localVarFp.updateToken(token, updateTokenRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
    /**
     * 
     * @summary Create a new store
     * @param {string} storeId ID of store to create
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public createStore(storeId: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).createStore(storeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Start a new upload
     * @param {string} storeId ID of the store containing the upload
     * @param {CreateStoreUploadRequest} createStoreUploadRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public createStoreUpload(storeId: string, createStoreUploadRequest: CreateStoreUploadRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).createStoreUpload(storeId, createStoreUploadRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create a new token for current user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public createToken(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).createToken(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete an existing store
     * @param {string} storeId ID of store to delete
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public deleteStore(storeId: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).deleteStore(storeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete a token for current user
     * @param {string} token ID of the token to delete
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public deleteToken(token: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).deleteToken(token, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Expire store upload and consider files for GC
     * @param {number} uploadId ID of the upload to fetch
     * @param {string} storeId ID of the store containing the upload
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public expireStoreUpload(uploadId: number, storeId: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).expireStoreUpload(uploadId, storeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Request download URL for the binary blob associated with a particular store/file/blob-id
     * @param {string} storeId ID of the store containing the file
     * @param {string} fileId ID of the file
     * @param {string} blobId ID of the blob
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getStoreFileBlobDownloadUrl(storeId: string, fileId: string, blobId: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getStoreFileBlobDownloadUrl(storeId, fileId, blobId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Fetch a list of blobs for a specific file in store
     * @param {string} storeId ID of the store containing the file
     * @param {string} fileId ID of the file
     * @param {string} [sort] Sort the content of the response according to a given key, potentially in descending order
     * @param {number} [offset] How many entries to skip (used for pagination of results)
     * @param {number} [limit] Max number of results to return (used for pagination of results)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getStoreFileBlobs(storeId: string, fileId: string, sort?: string, offset?: number, limit?: number, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getStoreFileBlobs(storeId, fileId, sort, offset, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Fetch a list of files in store
     * @param {string} storeId ID of the store containing the files
     * @param {string} [sort] Sort the content of the response according to a given key, potentially in descending order
     * @param {number} [offset] How many entries to skip (used for pagination of results)
     * @param {number} [limit] Max number of results to return (used for pagination of results)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getStoreFiles(storeId: string, sort?: string, offset?: number, limit?: number, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getStoreFiles(storeId, sort, offset, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Fetch an upload
     * @param {number} uploadId ID of the upload to fetch
     * @param {string} storeId ID of the store containing the upload
     * @param {string} [sort] Sort the content of the response according to a given key, potentially in descending order
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getStoreUpload(uploadId: number, storeId: string, sort?: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getStoreUpload(uploadId, storeId, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Fetch a list of uploads in store
     * @param {string} storeId ID of the store containing the uploads
     * @param {string} [sort] Sort the content of the response according to a given key, potentially in descending order
     * @param {number} [offset] How many entries to skip (used for pagination of results)
     * @param {number} [limit] Max number of results to return (used for pagination of results)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getStoreUploads(storeId: string, sort?: string, offset?: number, limit?: number, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getStoreUploads(storeId, sort, offset, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Fetch a list of all stores
     * @param {string} [sort] Sort the content of the response according to a given key, potentially in descending order
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getStores(sort?: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getStores(sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Fetch a token for current user
     * @param {string} token ID of the token to fetch
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getToken(token: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getToken(token, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Fetch a list of all tokens for current user
     * @param {string} [sort] Sort the content of the response according to a given key, potentially in descending order
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getTokens(sort?: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getTokens(sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Mark an upload as aborted
     * @param {number} uploadId ID of the upload to mark as aborted
     * @param {string} storeId ID of the store containing the upload
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public markStoreUploadAborted(uploadId: number, storeId: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).markStoreUploadAborted(uploadId, storeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Mark an upload as completed
     * @param {number} uploadId ID of the upload to fetch
     * @param {string} storeId ID of the store containing the upload
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public markStoreUploadCompleted(uploadId: number, storeId: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).markStoreUploadCompleted(uploadId, storeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Mark a file within an upload as uploaded
     * @param {number} uploadId ID of the upload to fetch
     * @param {string} storeId ID of the store containing the upload
     * @param {number} fileId Index of the file within the upload that should be marked as uploaded
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public markStoreUploadFileUploaded(uploadId: number, storeId: string, fileId: number, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).markStoreUploadFileUploaded(uploadId, storeId, fileId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update details of a token for current user
     * @param {string} token ID of the token to update
     * @param {UpdateTokenRequest} updateTokenRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public updateToken(token: string, updateTokenRequest: UpdateTokenRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).updateToken(token, updateTokenRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


