var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('v-data-table',{attrs:{"headers":_setup.headers,"items":_setup.storeUploads,"server-items-length":_setup.total,"options":_setup.options,"footer-props":{
      showCurrentPage: true,
      showFirstLastPage: true,
      itemsPerPageOptions: [
        10,
        25,
        100,
      ],
    }},on:{"update:options":_setup.updateOptions},scopedSlots:_vm._u([{key:"item.uploadId",fn:function({ item }){return [_c('router-link',{attrs:{"to":{ name: 'storeUpload', params: { store: _vm.store, upload: item.uploadId } }}},[_vm._v(_vm._s(item.uploadId))])]}},{key:"item.description",fn:function({ item }){return [_c('router-link',{attrs:{"to":{ name: 'storeUpload', params: { store: _vm.store, upload: item.uploadId } }}},[_vm._v(_vm._s(item.description))])]}},{key:"item.buildId",fn:function({ item }){return [_c('router-link',{attrs:{"to":{ name: 'storeUpload', params: { store: _vm.store, upload: item.uploadId } }}},[_vm._v(_vm._s(item.buildId))])]}},{key:"item.uploadTimestamp",fn:function({ item }){return [_c('router-link',{attrs:{"to":{ name: 'storeUpload', params: { store: _vm.store, upload: item.uploadId } }}},[_vm._v(_vm._s(_setup.timestampToDisplayString(item.uploadTimestamp)))])]}},{key:"item.expiryTimestamp",fn:function({ item }){return [_c('router-link',{attrs:{"to":{ name: 'storeUpload', params: { store: _vm.store, upload: item.uploadId } }}},[_vm._v(_vm._s(item.expiryTimestamp && _setup.timestampToDisplayString(item.expiryTimestamp) || ""))])]}},{key:"item.status",fn:function({ item }){return [_c('router-link',{attrs:{"to":{ name: 'storeUpload', params: { store: _vm.store, upload: item.uploadId } }}},[_vm._v(_vm._s(item.status))])]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }